import {get} from 'lodash';
import {utc} from 'moment';
import {BehaviorSubject} from 'rxjs';

import {enumerable} from '../../../util/enum';

export function getUserStatisticListConfig(taskId) {
  const that = this;

  const displayedColumns = ['userName', 'releaseName', 'releaseNumber', 'status'];

  const headers = [
    {
      key: 'userName',
      name: 'TRAININGS.TABLE_USER_NAME_HEADER',
      flex: 5,
      disableSort: true,
      transform: function(row) {
        const {firstName = '', lastName = '', email} = row.assignedTo;
        if (!firstName.length || !lastName.length) {
          return `<span>${email}</span>`;
        }
        return `<span>${firstName} ${lastName}</span>
                    <span>${email}</span>`;
      }
    },
    {
      key: 'releaseName',
      name: 'TRAININGS.TABLE_RELEASE_NAME_HEADER',
      flex: 3,
      disableSort: true,
      transform: function(row) {
        return row.connectionId.release.name;
      }
    },
    {
      key: 'releaseNumber',
      name: 'TRAININGS.TABLE_RELEASE_NUMBER_HEADER',
      flex: 3.5,
      disableSort: true,
      transform: function(row) {
        const releaseNumber = get(row, 'connectionId.release.number');
        if (releaseNumber) {
          const {major, minor, patch} = releaseNumber;
          return [major, minor, patch].join('.');
        }
        return '';
      }
    },
    {
      key: 'status',
      name: 'TRAININGS.TABLE_STATUS_HEADER',
      flex: 3,
      disableSort: true,
      transform: function(row) {
        return transformTaskTitle(row);
      }
    },
    {
      key: 'date',
      name: 'TRAININGS.TABLE_COMPLETED_DATE_HEADER',
      flex: 4,
      disableSort: true,
      transform: function(row) {
        if (row.status !== 'completed') {
          return '';
        }
        return `${utc(row.signOffDate).format('MMM D, YYYY h:mm A')}`;
      }
    },
    {
      key: 'completed',
      name: 'TRAININGS.TABLE_COMPLETION_PERCENTAGE_HEADER',
      disableSort: true,
      flex: 3,
      transform(row) {
        if (row.status === 'cancelled') {
          return '';
        }
        return `<span class="ml-10">${row.completed} %</span>`;
      }
    }
  ];

  return {
    paginated: true,
    paginationDefaults: {
      skip: 0,
      limit: 10,
      sortBy: 'releaseNumber',
      order: 'desc'
    },
    displayedColumns,
    hideSorting: true,
    pageSizeOptions: [10, 25, 50, 100],
    pageSize: 10,
    callServer: that.data.daoService.getUsersTaskStatistic.bind(this.data.daoService, taskId),
    filter: new BehaviorSubject(null),
    headers
  };
}

export function transformTaskTitle(row): string {
  const taskTypes = enumerable.taskList.types;
  switch (row.type) {
    case taskTypes.training:
      return transformTrainingTaskTitle(row.status);
    case taskTypes.authorisation:
      return transformAuthorisationTaskTitle(row.status);
  }
}

function transformTrainingTaskTitle(status): string {
  switch (status) {
    case 'notStarted':
      return 'Not started';
    case 'inProgress':
      return 'In progress';
    case 'readyToApprove':
      return 'Ready to acknowledge';
    case 'cancelled':
      return 'Canceled';
    case 'completed':
      return 'Acknowledged';
    case 'rejected':
      return 'Rejected';
  }
}

function transformAuthorisationTaskTitle(status): string {
  switch (status) {
    case 'notStarted':
    case 'inProgress':
      return 'Not started';
    case 'readyToApprove':
      return 'Accepted';
    case 'cancelled':
      return 'Canceled';
    case 'completed':
      return 'Authorized';
    case 'rejected':
      return 'Rejected';
  }
}
