import {CommonModule} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {
  FormGroupDirective,
  FormsModule,
  NgForm,
  ReactiveFormsModule,
  UntypedFormControl
} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {ErrorStateMatcher} from '@angular/material/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {TranslateModule} from '@ngx-translate/core';
import {Q9UserInfoModule} from '@q9elements/ui-core';
import * as _ from 'lodash';
import {Observable, of as observableOf} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  tap
} from 'rxjs/operators';

import {RefModelsSharedDaoService} from '../../services/referencemodels/ref-models-shared-dao.service';

@Component({
  selector: 'ref-model-change-owner',
  standalone: true,
  templateUrl: './ref-model-change-owner.component.html',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    Q9UserInfoModule,
    MatIconModule
  ],
  styleUrls: ['./ref-model-change-owner.component.scss']
})
export class RefModelChangeOwnerComponent implements OnInit, ErrorStateMatcher {
  error: string | null;
  matError: string;

  searchControl: UntypedFormControl;
  users$: Observable<any>;

  refModelId: string;
  noCandidates: boolean;
  selectedUser: any;

  constructor(
    private refOrgSharedDaoService: RefModelsSharedDaoService,
    public dialogRef: MatDialogRef<RefModelChangeOwnerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.refModelId = this.data.id;
    this.searchControl = new UntypedFormControl();
    this.setupCandidatesObserver();
  }

  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && isSubmitted && (control.invalid || this.matError));
  }

  onSelectHandler(evt) {
    this.matError = '';
    this.selectedUser = evt.option.value;
  }

  submit() {
    if (this.noCandidates) {
      return (this.matError = 'REFERENCE_MODELS.CHANGE_OWNER_USER_NOT_FOUND');
    }
    if (!this.selectedUser) {
      this.matError = 'REFERENCE_MODELS.CHANGE_OWNER_USER_NOT_SPECIFIED';
      return;
    }

    this.refOrgSharedDaoService.changeOwner(this.refModelId, this.selectedUser).subscribe(
      res => {
        this.dialogRef.close(res);
      },
      failure => {
        this.error = failure.error;
      }
    );
  }

  displayFn(value) {
    return value && value.firstName + ' ' + value.lastName;
  }

  setupCandidatesObserver() {
    this.users$ = this.searchControl.valueChanges.pipe(
      startWith(''),
      debounceTime(200),
      distinctUntilChanged(),
      filter(userInput => _.isString(userInput)),
      switchMap(val => {
        this.selectedUser = null;

        if (!val || val.length < 2) {
          this.matError = 'REFERENCE_MODELS.CHANGE_OWNER_USER_NOT_SPECIFIED';
          return observableOf(null);
        }
        return this.filter(val);
      })
    );
  }

  filter(value) {
    return this.refOrgSharedDaoService.refModelOwnerCandidates(this.refModelId, value).pipe(
      map(response =>
        _.filter(response, (val: {id: string}) => val && val.id !== this.data.owner.id)
      ),
      tap(response => {
        this.noCandidates = !response.length;
        if (this.noCandidates) {
          this.matError = 'REFERENCE_MODELS.CHANGE_OWNER_USER_NOT_FOUND';
        }
      })
    );
  }
}
