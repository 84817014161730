import {CommonModule} from '@angular/common';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {ErrorHandler, Injectable, NgModule} from '@angular/core';
import {AngularFireModule, FIREBASE_OPTIONS} from '@angular/fire/compat';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {AngularFireDatabaseModule} from '@angular/fire/compat/database';
import {AngularFirestoreModule} from '@angular/fire/compat/firestore';
import {AngularFirePerformanceModule} from '@angular/fire/compat/performance';
import {MatDialogModule} from '@angular/material/dialog';
import {MAT_PAGINATOR_DEFAULT_OPTIONS} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouteReuseStrategy} from '@angular/router';
import {Q9maFeedbackDaoService, Q9maGridService} from '@q9elements/ma';
import {
  AppName,
  Q9AttachmentDialogService,
  Q9AttachmentHelperService,
  Q9ConfirmDialogComponent,
  Q9CoreConfigService,
  Q9EditAttachmentModule,
  Q9LockDialogComponent,
  Q9NotificationsService,
  Q9ScreenshotModule,
  Q9SnackBarModule,
  Q9SnackBarService,
  Q9TaskNotificationsService,
  Q9TranslateForRootModule
} from '@q9elements/ui-core';
import {IntercomModule} from 'ng-intercom';
import * as Raven from 'raven-js';

import {environment} from '../environments/environment';
import {AppComponent} from './app.component';
import {AuthInterceptor} from './app.interceptors';
import {AppRouterModule} from './app.routes';
import {CustomRouteReuseStrategy} from './custom-route-reuse-strategy';
import {FeedbackDaoService} from './feedback/services/feedback-dao.service';
import {enumerable} from './util/enum';
import {EmptyComponent} from './_main/components/empty/empty.component';
import {AttachmentDialogService} from './_shared/components/attachments/services/attachment-dialog.service';
import {AttachmentHelperService} from './_shared/components/attachments/services/attachment-helper.service';
import {Q9GridService} from './_shared/components/q9-grid/services/q9-grid.service';
import {MatDatepickerModule} from '@angular/material/datepicker';

Raven.config(environment.RAVEN_DSN, {
  whitelistUrls: [/q9elements\.com/, /elements\.cloud/],
  ignoreErrors: enumerable.ignoreErrors
}).install();

@Injectable()
export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    if (environment.production) {
      Raven.captureException(err);
    }
    console.error(err);
  }
}

Q9ScreenshotModule.api(environment.API_ENDPOINT);
// Overriding default options for automation env
Q9SnackBarService.changeDefaultOptions({duration: environment.TOASTR_TIMEOUT || 3000});
Q9CoreConfigService.setCoreConfigs({environment, appName: AppName.MA});

@NgModule({
  declarations: [AppComponent, EmptyComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRouterModule,
    MatDialogModule,
    Q9SnackBarModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularFireModule,
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    Q9LockDialogComponent,
    Q9TranslateForRootModule,
    Q9ConfirmDialogComponent,
    AngularFirePerformanceModule,
    IntercomModule.forRoot({appId: environment.INTERCOM_APP_ID, updateOnRouterChange: true}),
    MatProgressSpinnerModule,
    MatDatepickerModule,
    Q9EditAttachmentModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: FIREBASE_OPTIONS, useValue: environment.fbConfig},
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: {
        formFieldAppearance: 'fill'
      }
    },
    {provide: ErrorHandler, useClass: RavenErrorHandler},
    {provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy},
    {provide: Q9AttachmentHelperService, useClass: AttachmentHelperService},
    {provide: Q9AttachmentDialogService, useClass: AttachmentDialogService},
    {provide: Q9maFeedbackDaoService, useClass: FeedbackDaoService},
    {provide: Q9maGridService, useClass: Q9GridService},
    Q9NotificationsService,
    Q9TaskNotificationsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
