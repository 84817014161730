const sfRoles = [
  {
    title: 'Salesforce Admin',
    key: 'salesforce_admin'
  },
  {
    title: 'Salesforce Developer',
    key: 'salesforce_developer'
  },
  {
    title: 'Salesforce Business Analyst',
    key: 'salesforce_business_analyst'
  },
  {
    title: 'Salesforce Consultant',
    key: 'salesforce_consultant'
  },
  {
    title: 'Salesforce Architect',
    key: 'salesforce_architect'
  },
  {
    title: 'Process Professional',
    key: 'process_professional'
  },
  {
    title: 'IT Manager/Executive',
    key: 'it_manager_executive'
  },
  {
    title: 'Business Manager/Executive',
    key: 'business_manager_executive'
  },
  {
    title: 'Rather not say',
    key: 'rather_not_say'
  },
  {
    title: 'End user',
    key: 'end_user'
  }
];

export const enumerable: any = {
  datePickerPlaceholder: 'YYYY-MM-DD',
  datePickerConfig: {
    appendTo: 'body',
    format: 'YYYY-MM-DD',
    firstDayOfWeek: 'mo'
  },
  pageSizeOptions: [10, 25, 50, 100],
  defaultGridPageSize: 100,
  priorityArray: [
    {title: 'Lowest', id: 0, color: '#3266a7'},
    {title: 'Low', id: 1, color: '#397d7c'},
    {title: 'Medium', id: 2, color: '#268432'},
    {title: 'High', id: 3, color: '#ca7d43'},
    {title: 'Highest', id: 4, color: '#a73640'}
  ],
  defaultPriorityValue: {title: 'Medium', id: 2, color: '#268432'},
  defaultParentRequirement: {summary: 'None', id: null},
  defaultCustomDropdownPlaceholder: 'Please select an option',
  routePaths: {
    teams: {
      navItemName: 'teams'
    },
    maps: {
      navItemName: 'maps'
    },
    architecture: {
      navItemName: 'architecture'
    },
    requirements: {
      navItemName: 'changes'
    },
    stories: {
      navItemName: 'changes'
    },
    'referencemodels-external': {
      navItemName: 'referencemodels-external'
    },
    referencemodels: {
      navItemName: 'referencemodels'
    },
    releases: {
      navItemName: 'releases'
    },
    'url-library': {
      navItemName: 'url-library'
    },
    feedback: {
      navItemName: 'changes'
    }
  },
  navItems: [
    {
      name: 'maps',
      routePaths: ['maps'],
      ref: 'active',
      svgIcon: 'q9-maps',
      text: 'DIAGRAMS'
    },
    // {
    //   name: 'architecture',
    //   routePaths: ['architecture'],
    //   ref: 'active',
    //   svgIcon: 'q9-architecture',
    //   text: 'ARCHITECTURE'
    // },
    {
      name: 'changes',
      routePaths: ['requirements', 'stories', 'feedback'],
      svgIcon: 'q9-requirements',
      text: 'REQUIREMENTS'
    },
    {
      name: 'referencemodels-external',
      routePaths: ['referencemodels-external'],
      ref: 'active',
      svgIcon: 'q9-sf',
      text: 'SF_ORG_CONFIG'
    },
    {
      name: 'referencemodels',
      routePaths: ['referencemodels'],
      ref: 'active',
      svgIcon: 'q9-ref-models',
      text: 'REFERENCE_MODEL'
    },
    {
      name: 'url-library',
      routePaths: ['url-library'],
      ref: 'active',
      svgIcon: 'q9-url-library',
      text: 'URL_LIBRARY'
    },
    {
      name: 'releases',
      routePaths: ['releases'],
      ref: 'active',
      svgIcon: 'q9-releases',
      text: 'RELEASES'
    }
  ],
  numberFilterOperators: [
    {title: 'Is equal to', value: 'eq'},
    {title: 'Is not equal to', value: 'neq'},
    {title: 'Is greater than or equal to', value: 'gte'},
    {title: 'Is greater than', value: 'gt'},
    {title: 'Is less than or equal to', value: 'lte'},
    {title: 'Is less than', value: 'lt'},
    {title: 'Is empty', value: 'isempty'},
    {title: 'Is not empty', value: 'isnotempty'}
  ],
  defaultNumberOperator: {title: 'Is equal to', value: 'eq'},
  stringFilterOperators: [
    {title: 'Is equal to', value: 'eq'},
    {title: 'Is not equal to', value: 'neq'},
    {title: 'Contains', value: 'contains'},
    {title: 'Does not contain', value: 'doesnotcontain'},
    {title: 'Starts with', value: 'startswith'},
    {title: 'Ends with', value: 'endswith'},
    {title: 'Is empty', value: 'isempty'},
    {title: 'Is not empty', value: 'isnotempty'}
  ],
  textareaFilterOperators: [
    {title: 'Contains', value: 'contains'},
    {title: 'Does not contain', value: 'doesnotcontain'},
    {title: 'Is empty', value: 'isempty'},
    {title: 'Is not empty', value: 'isnotempty'}
  ],
  defaultStringOperator: {title: 'Contains', value: 'contains'},
  customFilterOperators: [
    {title: 'Is equal to', value: 'eq'},
    {title: 'Is not equal to', value: 'neq'}
  ],
  defaultCustomOperator: {title: 'Is equal to', value: 'eq'},
  defaultDateFormat: 'YYYY-MM-DD',
  sfRoles: sfRoles,
  sfRolesIncludingOther: [...sfRoles, {title: 'Other', key: 'other'}],
  ignoreErrors: [
    'unauthorized',
    'no_password',
    'authentication_failed',
    'The database connection is closing',
    'A network error (such as timeout, interrupted connection or unreachable host) has occurred.\n',
    'Unspecified error.'
  ],
  usernameRegex: /^[^:.&]*$/,
  jiraErrorMessages: {
    issueDoesNotExist: 'Issue does not exist or you do not have permission to see it.'
  },
  defaultTextAreaColumnHeaderWidth: 600,
  managedPackageURL:
    'https://appexchange.salesforce.com/appxListingDetail?listingId=a0N3A00000EJicYUAT',
  kibanaEventTypes: {
    FEEDBACK_OPENED: 'Feedback grid opened',
    FEEDBACK_RESOLVED: 'Feedback resolved in the grid'
  },
  lastVisitedTeamsMaxCount: 10,

  release: {
    releaseTypeList: [
      {title: 'Patch', id: 0, color: '#268432', className: 'impact-color-patch'},
      {title: 'Minor', id: 1, color: '#ca7d43', className: 'impact-color-minor'},
      {title: 'Major', id: 2, color: '#a73640', className: 'impact-color-major'}
    ],
    riskLevelList: [
      {title: 'Low', id: 0, color: '#268432', className: 'risk-color-low'},
      {title: 'Medium', id: 1, color: '#ca7d43', className: 'risk-color-medium'},
      {title: 'High', id: 2, color: '#a73640', className: 'risk-color-high'}
    ]
  },
  taskList: {
    types: {
      training: 'DiagramTrainingTask',
      authorisation: 'DiagramAuthorisationTask'
    },
    authorisationTaskStatuses: {
      notStarted: 'notStarted',
      inProgress: 'inProgress',
      readyToApprove: 'readyToApprove',
      completed: 'completed',
      cancelled: 'cancelled',
      rejected: 'rejected'
    },
    closedAuthorisationTaskStatusesList: ['completed', 'cancelled', 'rejected'],
    taskRequestStatusList: {
      open: 'open',
      closed: 'closed'
    }
  },
  storiesRisksLevel: ['Unassessed', 'Low', 'Medium', 'High'],
  csvHeaders: {
    stories: [
      'Summary',
      'Story ID',
      'Assigned to',
      'Description',
      'Affected Roles',
      'Acceptance Criteria',
      'Status',
      'Risk',
      'Release',
      'Tags',
      'Parent requirement',
      'System ID',
      'External ID'
    ],
    requirements: [
      'Summary',
      'What is required',
      'Assigned to',
      'Status',
      'ID',
      'Priority',
      'Tags',
      'Impact',
      'Risk',
      'Release',
      'Required by',
      'Required by reason',
      'How might it be implemented',
      'System ID'
    ]
  },
  policyLink: 'https://app.box.com/s/rrjxamujw6xb15py9116tuim219odfrj'
};

export enum CUSTOM_FIELD_TYPE {
  TEXT = 'text',
  NUMBER = 'number',
  LIST = 'list',
  DATE_TIME = 'datetime',
  LONG_TEXT = 'longtext',
  TAG_LIST = 'taglist'
}

export type RefModelTogglesType = 'gdprEnabled' | 'dataPopulationV3Enabled';

export enum AFFECTED_ROLES_STATE {
  ACTIVE = 'active',
  DELETED = 'deleted'
}

export enum CONFLICT_STATUS {
  RESOLVED = 'Resolved',
  OPEN = 'Open'
}
