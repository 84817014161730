import {CommonModule} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {
  AbstractControl,
  FormGroupDirective,
  NgForm,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {ErrorStateMatcher} from '@angular/material/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {Q9HtmlPipesModule, Q9SnackBarService, Q9UserInfoModule} from '@q9elements/ui-core';
import {get, isObject, isString} from 'lodash';
import {Observable, of as observableOf} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  tap
} from 'rxjs/operators';

import {AuthService} from '../../../services/auth/auth.service';
import {RefModelsSharedDaoService} from '../../services/referencemodels/ref-models-shared-dao.service';

@Component({
  selector: 'ref-model-invite',
  standalone: true,
  templateUrl: './ref-model-invite.component.html',
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    Q9UserInfoModule,
    MatAutocompleteModule,
    MatIconModule,
    ReactiveFormsModule,
    Q9HtmlPipesModule,
    MatTooltipModule
  ],
  styleUrls: ['./ref-model-invite.component.scss']
})
export class RefModelInviteComponent implements OnInit, ErrorStateMatcher {
  users$: Observable<any>;
  refModelInviteForm: UntypedFormGroup;

  error: string | null;
  refModelName: string;
  refModelId: string;
  candidatesCounter: number;
  usersInSpace = [];
  existingUsers = [];
  isInviteToSpace = false;

  submitted: boolean;
  isExternal: boolean;
  inviteWarning: string;

  constructor(
    private refOrgSharedDaoService: RefModelsSharedDaoService,
    public dialogRef: MatDialogRef<RefModelInviteComponent>,
    private authService: AuthService,
    private q9snackBar: Q9SnackBarService,
    private translate: TranslateService,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isExternal = data.external;
  }

  ngOnInit() {
    this.refModelId = this.data.id;
    this.refModelName = this.data.name;
    this.setupForm();
    this.setupCandidatesObserver();
    this.inviteWarning = this.isExternal
      ? 'REFERENCE_MODELS.SF_USER_OUT_OF_SPACE'
      : 'REFERENCE_MODELS.USER_OUT_OF_SPACE';
  }

  private setupForm(): void {
    this.refModelInviteForm = this.formBuilder.group({
      addedUser: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          this.userValidator.bind(this)
        ])
      ],
      role: ['view'],
      message: ['', Validators.maxLength(5000)]
    });
  }

  userValidator(control: AbstractControl) {
    return (
      (control.value &&
        (isObject(control.value) || RefModelInviteComponent.isEmail(control.value))) || {
        invalidUser: true
      }
    );
  }

  submit() {
    if (this.submitted || !this.refModelInviteForm.valid) {
      this.error = null;
      return;
    }

    this.submitted = true;

    const data = this.refModelInviteForm.value;

    data.message = isString(data.message) ? data.message.trim() : null;
    const isEmail = isString(data.addedUser);
    const userData = isEmail ? data.addedUser : get(data.addedUser, 'id', data.addedUser);

    this.refOrgSharedDaoService
      .inviteIntoRefModel(this.refModelId, userData, data.role, data.message, isEmail)
      .subscribe(
        response => {
          this.dialogRef.close(response);
          const user = isEmail ? data.addedUser : this.buildName(data.addedUser);
          const message = `${this.translate.instant('REFERENCE_MODELS.ADDED_USER')} ${user}`;
          this.q9snackBar.show(message);
        },
        (failure: any) => {
          const {error} = failure;

          this.submitted = false;

          if (error.email) {
            const {email, error: errMessage} = error;

            return (this.error = email + ': ' + errMessage);
          }

          this.error = error.error;
        }
      );
  }

  private setupCandidatesObserver(): void {
    this.users$ = this.refModelInviteForm.get('addedUser').valueChanges.pipe(
      startWith(''),
      tap(val => {
        if (!val || val.length < 2) {
          this.isInviteToSpace = false;
        }
      }),
      debounceTime(200),
      distinctUntilChanged(),
      filter(userInput => isString(userInput)),
      switchMap((val: any) => {
        if (!val || val.length < 2) {
          return observableOf(null);
        }
        return this.filter(val);
      })
    );
  }

  private filter(value) {
    return this.refOrgSharedDaoService.suggestInvite(this.refModelId, value).pipe(
      map(({usersToAdd, existingUsers}) => {
        this.existingUsers = existingUsers;
        this.usersInSpace = [...usersToAdd];
        this.candidatesCounter = usersToAdd.length;
        this.isInviteToSpace = this.isInviteByEmail();

        return usersToAdd;
      })
    );
  }

  removeUser() {
    this.submitted = false;
    this.refModelInviteForm.patchValue({
      addedUser: '',
      role: 'view',
      message: ''
    });
  }

  isInviteByEmail() {
    const field = this.refModelInviteForm.get('addedUser').value;
    const existsInSpace = this.existingUsers.some(user => user.email.includes(field));

    return (
      !this.candidatesCounter && !existsInSpace && field && isString(field) && field.length > 3
    );
  }

  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return control && isSubmitted && control.invalid;
  }

  displayFn(user: {firstName: string; lastName: string; email: string}): string {
    const {firstName, lastName, email} = user;

    if (!firstName && !lastName) {
      return user && email;
    }

    return user && firstName + ' ' + lastName;
  }

  onSelectHandler(): void {
    this.refModelInviteForm.get('role').setValue('view');
  }

  isRoleDisabled(): boolean {
    const candidate = this.refModelInviteForm.get('addedUser').value;
    const userInSpace = this.usersInSpace.find(user => user.email === candidate);

    if (userInSpace?.teamPermission.role === 'viewer') {
      this.onSelectHandler();

      return true;
    }

    return !candidate || (isObject(candidate) && candidate.teamPermission.role === 'viewer');
  }

  private buildName(user: {email?: string; firstName?: string; lastName?: string} | undefined) {
    if (!isString(user.firstName) || (!isString(user.lastName) && user.email)) {
      return user.email;
    }

    return `${user.firstName} ${user.lastName}`;
  }

  static isEmail(value) {
    const emailMatch =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return isString(value) && emailMatch.test(value.toLowerCase());
  }
}
